/**
 * @file Get a party response
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useQuery } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const GET_PARTY_RESPONSE_URL = '/api/party/response'

const getPartyResponse = async (id, accessToken) =>
  get(`${GET_PARTY_RESPONSE_URL}/${id}`, { accessToken })

const useGetPartyResponse = partyID => {
  const { user } = useContext(RootContext)
  return useQuery(
    ['party-response', partyID],
    async () => getPartyResponse(partyID, user.accessToken),
    {
      enabled: !!partyID && !!user.accessToken,
      retry: false,
      refetchOnWindowFocus: false,
    }
  )
}

export default useGetPartyResponse
