/**
 * @file Saves a party response
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import RootContext from 'RootContext'
import { put } from 'utils/api'

const SAVE_PARTY_RESPONSE_URL = '/api/party/response'

const savePartyResponse = async (id, response, accessToken) =>
  put(`${SAVE_PARTY_RESPONSE_URL}/${id}`, { response }, { accessToken })

const useSavePartyResponse = partyID => {
  const { user } = useContext(RootContext)
  const queryClient = useQueryClient()
  return useMutation(async response => {
    const r = await savePartyResponse(partyID, response, user.accessToken)
    queryClient.invalidateQueries(['party-response', partyID])
    return r
  })
}

export default useSavePartyResponse
