/**
 * @file Request Login Hook
 * @author Alwyn Tan
 */

import { useMutation } from 'react-query'
import { post } from 'utils/api'

const REQUEST_LOGIN_URL = `/api/user/request-login`

const postRequestLogin = async phoneNumber => {
  const { success } = await post(
    REQUEST_LOGIN_URL,
    { phoneNumber },
    { includeCredentials: true }
  )

  return { success }
}

const useRequestLogin = () =>
  useMutation(phoneNumber => postRequestLogin(phoneNumber))

export default useRequestLogin
