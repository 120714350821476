/**
 * @file Update User Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import { serialize } from 'object-to-formdata'
import RootContext from 'RootContext'

const UPDATE_USER_URL = `/api/user`

const putUpdateUser = async (id, details, accessToken) => {
  const body = serialize(details)

  return fetch(`${UPDATE_USER_URL}/${id}`, {
    method: 'PUT',
    body,
    headers: { Authorization: `Bearer ${accessToken}` },
  }).then(response => response.json())
}

const useUpdateUser = () => {
  const { user, setUser } = useContext(RootContext)

  return useMutation(async details => {
    const response = await putUpdateUser(user.id, details, user.accessToken)
    setUser({ ...user, ...response.user })
    return { success: response.user }
  })
}

export default useUpdateUser
