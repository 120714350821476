/**
 * @file General Input For Solo Party
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import isMobilePhone from 'validator/lib/isMobilePhone'
import { useController } from 'react-hook-form'
import { motion } from 'framer-motion'

const Container = styled.div`
  background-color: #b57aff;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 15px;
  color: white;
  border-radius: 8px 8px ${({ error }) => (error ? '0 0' : '8px 8px')};
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 1.25rem;
  position: relative;
  z-index: 1;

  :before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    transition: 0.1s ease;
    border-style: solid;
    border-color: ${({ error, theme }) => (error ? theme.Error : '#ffffff00')};
    border-width: 4px;
    border-radius: inherit;
  }

  :focus-within {
    :before {
      border-color: ${({ error, theme }) => (error ? theme.Error : '#ffffff')};
    }
  }

  > input {
    color: white;
    background-color: inherit;
    outline: none;
    border: 0;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    padding: 0;

    ::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  }
`

const CountryCode = styled.span`
  transform: translateY(0.5px);
  padding-right: 15px;
`

const Error = styled(motion.h5)`
  background-color: ${({ theme }) => theme.Error};
  color: white;
  border-radius: 0 0 8px 8px;
  text-align: left;
  position: relative;
`

const ErrorMessage = styled(motion.span)`
  position: absolute;
  left: 0;
  right: 0;
  padding: 5px 15px 8px 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PhoneInput = ({
  name,
  control,
  defaultValue,
  onPhoneEntered,
  required,
}) => {
  const countryCode = '+1'

  const { field, fieldState } = useController({
    name,
    control,
    defaultValue,
    rules: {
      required,
      validate: value =>
        isMobilePhone(value || '') || 'Enter a valid US phone number',
    },
  })

  const transform = {
    input: value => value.replace(/[\s()-]+/g, '').replace(countryCode, ''),
    output: value => `${countryCode}${value.replace(/[\s()-]+/g, '')}`,
  }

  const hideError = field.value.length < 12

  return (
    <>
      <Container error={!hideError && fieldState.error}>
        <CountryCode>{countryCode}</CountryCode>
        <NumberFormat
          format="(###) ### ####"
          mask=" "
          placeholder="Your number"
          {...field}
          value={transform.input(field.value)}
          onChange={e => {
            const val = transform.output(e.target.value)
            field.onChange(val)
            if (isMobilePhone(val)) onPhoneEntered(val)
          }}
        />
      </Container>
      {/* <AnimatePresence> */}
      {!hideError && fieldState.error && (
        <Error
          initial={{ height: 0, opacity: 0 }}
          animate={{ height: 30, opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ ease: 'easeOut', duration: 0.2 }}
        >
          <ErrorMessage
            initial={{ y: -60 }}
            animate={{ y: 0 }}
            exit={{ y: -60 }}
          >
            {fieldState.error?.message || 'Required'}
          </ErrorMessage>
        </Error>
      )}
      {/* </AnimatePresence> */}
    </>
  )
}

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  required: PropTypes.bool,
  onPhoneEntered: PropTypes.func,
}

PhoneInput.defaultProps = {
  defaultValue: '',
  required: false,
  onPhoneEntered: () => {},
}

export default PhoneInput
