/**
 * @file App
 * @author Alwyn Tan
 */

import useSilentLogin from 'hooks/query/user/useSilentLogin'
import FourOhFour from 'pages/404'
import Auth from 'pages/Auth'
import PartyPage from 'pages/Party'
import React, { useEffect, useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import RootContext from 'RootContext'

const App = () => {
  const { user } = useContext(RootContext)
  const { mutate: silentLoginMutate } = useSilentLogin()

  useEffect(() => {
    window.analytics.ready(() => silentLoginMutate())
  }, [silentLoginMutate])

  if (!user) return <p>Loading</p>

  return (
    <>
      <Auth />
      <Switch>
        <Route exact path="/404" component={FourOhFour} />
        <Route exact path="/:id" component={PartyPage} />
        <Redirect from="*" to="/404" />
      </Switch>
    </>
  )
}

export default App
