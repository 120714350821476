/**
 * @file Request a login (phone input card)
 * @author Alwyn Tan
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import PhoneInput from 'components/simple/PhoneInput'
import { motion } from 'framer-motion'
import useRequestLogin from 'hooks/query/user/useRequestLogin'

const Container = styled(motion.div)`
  padding: 15px 15px 20px 15px;
  width: 100%;
  display: flex;
  justify-content: center;
`

const InnerContainer = styled.div`
  padding: 20px 20px 25px 20px;
  background: #a26cff;
  border-radius: 19px;
  width: 100%;
  text-align: center;
  max-width: 410px;
`

const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  color: white;
`

const Subtitle = styled.h3`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: white;
  padding-top: 5px;
  padding-bottom: 20px;
`

const Footer = styled.h6`
  font-family: Inter;
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  color: white;
  opacity: 0.5;
  padding-top: 10px;
`

const RequestLoginCard = ({
  phoneNumber,
  onLoginRequested,
  performBackAnimation,
}) => {
  const { control, setError } = useForm({ mode: 'onChange' })
  const requestLoginMutation = useRequestLogin()

  const handlePhoneEntered = async number => {
    try {
      await requestLoginMutation.mutateAsync(number)
      onLoginRequested(number)
    } catch (e) {
      setError('phoneNumber', {
        message: e.message,
      })
    }
  }

  return (
    <Container
      initial={{ x: performBackAnimation ? -1000 : 0 }}
      animate={{ x: 0 }}
      exit={{ x: -1000 }}
    >
      <InnerContainer>
        <Title>Your Response</Title>
        <Subtitle>Please enter your info to respond</Subtitle>
        <PhoneInput
          required
          control={control}
          name="phoneNumber"
          defaultValue={phoneNumber}
          onPhoneEntered={handlePhoneEntered}
        />
        <Footer>This information will only be shared with Tim</Footer>
      </InnerContainer>
    </Container>
  )
}

RequestLoginCard.propTypes = {
  phoneNumber: PropTypes.string,
  onLoginRequested: PropTypes.func.isRequired,
  performBackAnimation: PropTypes.bool,
}

RequestLoginCard.defaultProps = {
  phoneNumber: '',
  performBackAnimation: false,
}

export default RequestLoginCard
