/**
 * @file Get party
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useQuery } from 'react-query'
import RootContext from 'RootContext'
import { get } from 'utils/api'

const GET_PARTY_URL = '/api/party'

const getParty = async (id, accessToken) =>
  get(`${GET_PARTY_URL}/${id}`, { accessToken })

const useGetParty = id => {
  const { user } = useContext(RootContext)
  return useQuery(['party', id], async () => getParty(id, user.accessToken), {
    enabled: !!id,
    retry: false,
    refetchOnWindowFocus: false,
  })
}

export default useGetParty
