/**
 * @file Silent Login Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const SILENT_LOGIN_URL = '/api/user/silent-login'
const RESOLVE_ANON_URL = '/api/user/resolve-anon'

const postSilentLogin = async () => {
  try {
    return await post(SILENT_LOGIN_URL, {}, { includeCredentials: true })
  } catch (e) {
    return {} // temporary
  }
}

const postResolveAnon = async accessToken =>
  post(RESOLVE_ANON_URL, {}, { accessToken })

const useSilentLogin = () => {
  const { setUser } = useContext(RootContext)

  return useMutation(async () => {
    const { accessToken, user } = await postSilentLogin()
    if (accessToken) {
      await postResolveAnon(accessToken)
      delete user.fcmTokens
      window.analytics.identify(user.id, user)
      setUser({ ...user, accessToken })
    } else {
      setUser({})
    }
    return { user }
  })
}

export default useSilentLogin
