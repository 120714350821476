/**
 * @file Onboarding Name Card
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import Input from 'components/simple/Input'
import useUpdateUser from 'hooks/query/user/useUpdateUser'

const Container = styled.div`
  padding: 15px 15px 20px 15px;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
`

const InnerContainer = styled.form`
  padding: 20px 20px 25px 20px;
  background: #757fff;
  border-radius: 19px;
  width: 100%;
  text-align: center;
  max-width: 410px;
`

const Title = styled.h2`
  font-family: Inter;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  color: white;
  padding-bottom: 20px;
`

const Footer = styled.h6`
  font-family: Inter;
  font-style: italic;
  font-weight: normal;
  font-size: 13px;
  color: white;
  opacity: 0.5;
  padding-top: 10px;
`

const DoneButton = styled.button`
  outline: none;
  border: none;
  background-color: white;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 100px;
  padding: 12px 30px;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`

const NameCard = () => {
  const { control, formState, handleSubmit } = useForm({ mode: 'onChange' })

  const updateUserMutation = useUpdateUser()

  const onSubmit = ({ name }) => {
    updateUserMutation.mutate({ name })
  }

  return (
    <Container>
      <InnerContainer onSubmit={handleSubmit(onSubmit)}>
        <Title>What’s your name?</Title>
        <Input
          required
          control={control}
          name="name"
          placeholder="Full Name"
          style={{ backgroundColor: '#8C97FF' }}
        />
        <Footer>This information will only be shared with Tim</Footer>
        <DoneButton
          disabled={!formState.isValid}
          onClick={handleSubmit(onSubmit)}
        >
          Done
        </DoneButton>
      </InnerContainer>
    </Container>
  )
}

export default NameCard
