/**
 * @file 404 page not found
 * @author Alwyn Tan
 */

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FourOhFour = () => (
  <Container>
    <h3>Page not found :(</h3>
  </Container>
)

export default FourOhFour
