/**
 * @file Api fetch helpers
 * @author Alwyn Tan
 */

/**
 * Makes a post request to the specified url
 * @param {String} url url to post to
 * @param {Object} body body in json format
 * @param {Object} options
 * @param {Boolean} options.includeCredentials include credentials on request
 * @param {String} options.accessToken User's Access Token, required for private routes
 */
export const post = async (url, body, options = {}) => {
  const headers = { 'Content-Type': 'application/json' }

  const request = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  }

  if (options.includeCredentials) request.credentials = 'include'
  else if (options.accessToken)
    headers.Authorization = `Bearer ${options.accessToken}`

  const response = await fetch(url, request)
  const json = await response.json()
  if (!response.ok) throw new Error(json.error?.message || 'Network Error')
  return json
}

/**
 * Makes a post request to the specified url
 * @param {String} url url to post to
 * @param {Object} body body in json format
 * @param {Object} options
 * @param {Boolean} options.includeCredentials include credentials on request
 * @param {String} options.accessToken User's Access Token, required for private routes
 */
export const put = async (url, body, options = {}) => {
  const headers = { 'Content-Type': 'application/json' }

  const request = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers,
  }

  if (options.includeCredentials) request.credentials = 'include'
  else if (options.accessToken)
    headers.Authorization = `Bearer ${options.accessToken}`

  const response = await fetch(url, request)
  const json = await response.json()
  if (!response.ok) throw new Error(json.error?.message || 'Network Error')
  return json
}

/**
 * Makes a get request to the specified url
 * @param {String} url url to get
 * @param {Object} options
 * @param {Boolean} options.includeCredentials include credentials on request
 * @param {String} options.accessToken User's Access Token, required for private routes
 */
export const get = async (url, options = {}) => {
  const headers = {}

  const request = { headers }
  if (options.includeCredentials) request.credentials = 'include'
  else if (options.accessToken)
    headers.Authorization = `Bearer ${options.accessToken}`

  const response = await fetch(url, request)
  const json = await response.json()
  if (!response.ok) throw new Error(json.error?.message || 'Network Error')
  return json
}
