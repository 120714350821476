/**
 * @file Login Hook
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const LOGIN_URL = '/api/user/login'
const RESOLVE_ANON_URL = '/api/user/resolve-anon'

const postLogin = async ({ phoneNumber, code }) =>
  post(LOGIN_URL, { phoneNumber, code }, { includeCredentials: true })

const postResolveAnon = async accessToken =>
  post(RESOLVE_ANON_URL, {}, { accessToken })

const useLogin = () => {
  const { setUser } = useContext(RootContext)

  return useMutation(async ({ phoneNumber, code }) => {
    const { accessToken, user } = await postLogin({ phoneNumber, code })
    if (accessToken) {
      await postResolveAnon(accessToken)
      delete user.fcmTokens
      window.analytics.identify(user.id, user)
      setUser({ ...user, accessToken })
    } else {
      setUser({})
    }
    return { user }
  })
}

export default useLogin
