/**
 * @file New party
 * @author Alwyn Tan
 */

import React, { useCallback, useContext, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import toast from 'react-hot-toast'
import SEO from 'components/simple/SEO'
import useGetParty from 'hooks/query/party/useGetParty'
import useGetPartyResponse from 'hooks/query/party/useGetPartyResponse'
import pin from 'images/pin.png'
import useSavePartyResponse from 'hooks/query/party/useSavePartyResponse'
import RootContext from 'RootContext'
import usePostViewParty from 'hooks/query/party/usePostViewParty'
import spinner from 'images/spinner.png'

const Container = styled.div`
  background-color: #131313;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const MaxMobileSizeContainer = styled.div`
  max-height: 896px;
  max-width: 414px;
  height: 100%;
  width: 100%;
  background-color: #0c0c0c;
  border-radius: 8px;
`

const Party = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 0;
  color: white;
  border-radius: inherit;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  > h2,
  > p {
    padding: 0 20px 0 13px;
  }
`

const GradientOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(38, 38, 38, 0) 0%,
    rgba(38, 38, 38, 0) 58.33%,
    #131313 100%
  );
`

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  pointer-events: none;
`

const Descriptions = styled(motion.div)`
  position: absolute;
  padding: 0 17px 13px 17px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  left: 0;
  right: 0;
  max-width: 170px;
`

const Location = styled.div`
  background-color: rgba(0, 0, 0, 0.3);
  padding: 6px;
  border-radius: 6px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  margin-top: 13px;
  cursor: pointer;
  max-width: 100%;
  align-items: center;

  > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Pin = styled.div`
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  width: 10px;
  height: 12.5px;
  min-width: 10px;
  margin-right: 6px;
`

const ButtonsContainer = styled(motion.div)`
  position: absolute;
  right: 10px;
  bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const Button = styled.button`
  border-radius: 29px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  padding: 12px 30px;
  outline: none;
  border: 0;
  color: white;
  margin-bottom: 20px;
  width: auto;
  cursor: pointer;
  background: ${({ response, value }) =>
    !response || response === value ? '#9E24BF' : 'rgba(255, 255, 255, 0.15)'};
  backdrop-filter: blur(115px);
  user-select: none;
  opacity: ${({ response, value }) =>
    !response || response === value ? 1 : 0.5};
`

const Spinner = styled(motion.div)`
  height: 25px;
  width: 25px;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: calc(50% - 25px / 2);
  left: calc(50% - 25px / 2);
`

const PartyPage = () => {
  const { id } = useParams()
  const { user, onAuthSuccess, setShowAuth, jsConfetti } =
    useContext(RootContext)
  const history = useHistory()
  const videoRef = useRef(null)
  const getPartyQuery = useGetParty(id)
  const { mutateAsync: postViewPartyMutateAsync } = usePostViewParty(id)
  const getPartyResponseQuery = useGetPartyResponse(id)
  const savePartyResponse = useSavePartyResponse(id)

  const party = getPartyQuery?.data?.party
  const partyResponse = getPartyResponseQuery?.data?.partyResponse?.response

  useEffect(() => {
    if (!party && !getPartyQuery?.isLoading) history.push('/404')
  }, [party, getPartyQuery?.isLoading, history])

  const onFocus = useCallback(async () => {
    if (party) {
      const saved = localStorage.getItem(`p-${id}`)
      if (!saved) {
        localStorage.setItem(`p-${id}`, true)
        await postViewPartyMutateAsync()
      }
      toast(`👀 You just viewed ${party?.leader?.name}'s Disco`)
      if (videoRef.current.paused) videoRef.current.play()
      window.document.removeEventListener('click', onFocus)
      window.document.removeEventListener('mouseenter', onFocus)
      window.document.removeEventListener('keydown', onFocus)
      window.document.removeEventListener('scroll', onFocus)
    }
  }, [id, party, postViewPartyMutateAsync])

  useEffect(() => {
    // only runs onFocus when users manually focus
    window.document.addEventListener('click', onFocus)
    window.document.addEventListener('mouseenter', onFocus)
    window.document.addEventListener('keydown', onFocus)
    window.document.addEventListener('scroll', onFocus)

    // Specify how to clean up after this effect:
    return () => {
      window.document.removeEventListener('click', onFocus)
      window.document.removeEventListener('mouseenter', onFocus)
      window.document.removeEventListener('keydown', onFocus)
      window.document.removeEventListener('scroll', onFocus)
    }
  }, [onFocus])

  const handleLocationClick = () => {
    window.open(`https://maps.google.com/?q=${party?.address}`, '_blank')
  }

  const handleResponseClick = async e => {
    const resp = e.currentTarget.value
    const showConfetti = () =>
      jsConfetti.addConfetti({
        emojis: [resp],
        emojiSize: 120,
      })

    try {
      await savePartyResponse.mutateAsync(resp)
      if (!user?.accessToken) onAuthSuccess.current = showConfetti
      else showConfetti()
      setShowAuth(true)
    } catch (error) {
      // tbd: catch error
    }
  }

  if (!party)
    return (
      <Spinner
        src={spinner}
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 0.5, ease: 'linear' }}
      />
    )

  return (
    <Container>
      <SEO
        title={party.title}
        description={party.description}
        video={party.video}
        image={party.thumbnail}
      />
      <MaxMobileSizeContainer>
        <Party>
          <GradientOverlay />
          <StyledVideo
            ref={videoRef}
            poster={party.thumbnail}
            src={party.video}
            muted
            autoPlay
            loop
            playsInline
            preload="metadata"
          />
          <Descriptions
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <h2>{party.title}</h2>
            <Location onClick={handleLocationClick}>
              <Pin src={pin} />
              <span>
                {party.location || (party.address || '').split(',')[0]}
              </span>
            </Location>
          </Descriptions>
          <ButtonsContainer
            initial={{ opacity: 0, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <Button
              response={partyResponse}
              value="🔥"
              onClick={handleResponseClick}
            >
              🔥 Let's Go
            </Button>
            <Button
              response={partyResponse}
              value="👍"
              onClick={handleResponseClick}
            >
              👍 Looks Cool
            </Button>
            <Button
              response={partyResponse}
              value="🤔"
              onClick={handleResponseClick}
            >
              🤔 Ehhh
            </Button>
            <Button
              response={partyResponse}
              value="💩"
              onClick={handleResponseClick}
            >
              💩 Looks Lame
            </Button>
          </ButtonsContainer>
        </Party>
      </MaxMobileSizeContainer>
    </Container>
  )
}

export default PartyPage
