// TODO: THIS IS TEMPORARY. ONCE SEGMENT IS INTEGRATED REMOVE THIS AND IMPLEMENT THE VIEW PARTY FLOW IN THE BACKEND FOR GET /party/:id

/**
 * @file Hook to save a user viewing a party (sends a notif to host)
 * @author Alwyn Tan
 */

import { useContext } from 'react'
import { useMutation } from 'react-query'
import RootContext from 'RootContext'
import { post } from 'utils/api'

const POST_VIEW_PARTY_URL = '/api/party/view'

const postViewParty = async (partyID, accessToken) =>
  post(POST_VIEW_PARTY_URL, { partyID }, { accessToken })

const usePostViewParty = partyID => {
  const { user } = useContext(RootContext)
  return useMutation(async () => postViewParty(partyID, user.accessToken))
}

export default usePostViewParty
